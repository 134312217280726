// @flow

import React from 'react';
import moment from 'moment';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { CoreozInputBase, CoreozDatePickerBase, CoreozSelectBase } from 'coreoz-form-base';
import { I18n } from 'react-redux-i18n';
import {
  required,
  composeValidators,
  validDate,
  alphabet,
  dateNotInFuture,
} from '../../validators';
import { DATE_FORMAT, DATE_PLACEHOLDER, GROUPED, INDIVIDUAL } from '../../const';
import Tooltip from '../tooltip/Tooltip';

type Props = {
  addBeneficiary: Function,
  removeBeneficiary: Function,
  handleDate: Function,
  data: Object,
  theme: Object,
  form: Object,
};

class Beneficiary extends React.PureComponent<Props> {

  getAge = (index) => {
    const birthDate = this.props.data.beneficiary[index]
      ? this.props.data.beneficiary[index].birthDay
      : NaN;
    const age = moment().diff(birthDate, 'years');
    return age > 0 ? age : 0;
  };

  triggerParent = (e) => {
    e.stopPropagation();
    e.target.parentNode.click();
  };

  toggleAccordion = (e) => {
    e.target.querySelector('i').classList.toggle('icon-arrow-down');
    e.target.querySelector('i').classList.toggle('icon-arrow-up');
    const event = Object.assign({}, e);
    if (event.target.classList.contains('u-accordion__open')) {
      event.target.classList.toggle('u-accordion__close');
      event.target.classList.toggle('u-accordion__open');
    } else {
      event.target.classList.toggle('u-accordion__close');
      event.target.classList.toggle('u-accordion__opening');
      setTimeout(() => {
        event.target.classList.toggle('u-accordion__opening');
        event.target.classList.toggle('u-accordion__open');
      }, 500);
    }
  };

  render() {
    const { data, removeBeneficiary, addBeneficiary, theme, handleDate, form } = this.props;
    const { color, company } = theme;
    return (
      <>
        <div className="div-tooltip">
          <h2 className="a-title__h2 u-margin-left-10" style={{ color }}>
            {I18n.t('requestCertificate.beneficiary.addbeneficiary')}
          </h2>
          <Tooltip message={I18n.t('requestCertificate.beneficiary.conditions')} color={theme.color} className="tooltip" />
        </div>
        <div className="o-beneficiary__toggle">
          <button
            type="button"
            className="a-icon__remove"
            onClick={() => removeBeneficiary('beneficiary')}
            style={{ background: data.beneficiary.length ? color : '' }}
          >
            <i className="icon-minus" />
          </button>
          <span className="a-text__count">{data.beneficiary.length}</span>
          <button
            type="button"
            className="a-icon__add"
            onClick={() => addBeneficiary('beneficiary', undefined)}
            style={{ background: color }}
          >
            <i className="icon-plus" />
          </button>
        </div>
        {data.beneficiary.length ? (
          <div className="o-beneficiary__certificate u-clearfix u-margin-left-10">
            <div className="o-beneficiary__certificate-left">
              <strong className="a-label__medium u-required">
                {I18n.t('requestCertificate.beneficiary.certificate')}
              </strong>
            </div>
            <div className="o-beneficiary__certificate-right">
              <div className={`a-input__radio ${company}`}>
                <label className="a-label__medium u-lowercase">
                  <Field name="holder.certificate" value={GROUPED} type="radio">
                    {({ input }) => (
                        <>
                          <input
                            name={input.name}
                            type="radio"
                            value={GROUPED}
                            checked={input.checked}
                            onChange={input.onChange}
                          />
                          <span className={`checkmark ${company}`} />
                        </>
                      )}
                  </Field>
                  {I18n.t('requestCertificate.beneficiary.grouped')}
                </label>
              </div>
              <div className={`a-input__radio ${company}`}>
                <label className="a-label__medium u-lowercase">
                  <Field name="holder.certificate" value={INDIVIDUAL} type="radio">
                    {({ input }) => (
                      <>
                        <input
                          name={input.name}
                          type="radio"
                          value={INDIVIDUAL}
                          checked={input.checked}
                          onChange={input.onChange}
                        />
                        <span className={`checkmark ${company}`} />
                      </>
                    )}
                  </Field>
                  {I18n.t('requestCertificate.beneficiary.individual')}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <FieldArray name="beneficiary">
          {({ fields }) => (
            <ul className="o-beneficiary__list">
              {fields.map((name, index) => (
                <li key={name}>
                  <button
                    type="button"
                    className="a-button__accordion u-accordion__open"
                    onClick={e => this.toggleAccordion(e)}
                  >
                    <span
                      className="a-title__h3"
                      onClick={e => this.triggerParent(e)}
                    >
                      {I18n.t('requestCertificate.beneficiary.beneficiary')}
                      <sub>&nbsp;&nbsp;{index + 1}</sub>
                    </span>
                    <i
                      className="icon-arrow-up"
                      onClick={e => this.triggerParent(e)}
                    />
                  </button>
                  <ul>
                    <li>
                      <Field
                        label={I18n.t('requestCertificate.beneficiary.name')}
                        name={`${name}.lastName`}
                        component={CoreozInputBase}
                        type="text"
                        validate={composeValidators(required, alphabet)}
                        defaultValue={data.holder.lastName}
                        required
                      />
                    </li>
                    <li>
                      <Field
                        label={I18n.t('requestCertificate.beneficiary.firstName')}
                        name={`${name}.firstName`}
                        component={CoreozInputBase}
                        type="text"
                        validate={composeValidators(required, alphabet)}
                        required
                      />
                    </li>
                    <li className="a-input__birthday">
                      <Field
                        label={I18n.t('requestCertificate.beneficiary.birthday')}
                        name={`${name}.birthDay`}
                        component={CoreozDatePickerBase}
                        locale="fr-fr"
                        validate={composeValidators(required, validDate, dateNotInFuture)}
                        dateFormat={DATE_FORMAT}
                        onlyDate
                        required
                        placeholder={DATE_PLACEHOLDER}
                        onChange={(e) => {handleDate(e, form.change, `${name}.birthDay`)}}
                        maxLength="10"
                      />
                      <span className="a-input__birthday--age">
                        {`${this.getAge(index)} ${I18n.t('requestCertificate.holder.year')}${this.getAge(index) > 1 ? 's' : ''}`}
                      </span>
                    </li>
                    <li>
                      <Field
                        label={I18n.t('requestCertificate.beneficiary.relationship')}
                        name={`${name}.relationship`}
                        component={CoreozSelectBase}
                        validate={required}
                        list={[
                          { id: 'Conjoint (mariage, pacs…)', label: 'Conjoint (mariage, pacs…)' },
                          { id: 'Concubin', label: 'Concubin' },
                          { id: 'Descendant', label: 'Descendant' },
                          { id: 'Ascendant ', label: 'Ascendant' },
                        ]}
                        required
                      />
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          )}
        </FieldArray>
      </>
    );
  }
}

export default Beneficiary;
