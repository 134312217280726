// @flow

import React from 'react';
import { ThemeContext } from '../store/ThemeContext';
import { getParamCompany } from './../utils';
import RequestCertificate from '../components/RequestCertificate/RequestCertificate';

type Props = {};

class RequestCertificatePage extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  componentDidMount() {
    this.context.setTheme(getParamCompany());
  }

  render() {
    return (
      <>
        <RequestCertificate theme={this.context.theme} />
      </>
    );
  }
}

export default RequestCertificatePage;
