// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { CoreozInputBase } from 'coreoz-form-base';
import { checkBin, composeValidators, required } from '../../validators';
import { REQUEST_CERTIFICATE_PAGE_ROUTE } from '../../const';
import { CertificateContext } from '../../store/CertificateContext';
import binApi from '../../network/api/binApi';
import { notifyError } from '../../network/notification';
import Header from '../Header/Header';
import covidApi from '../../network/api/covidApi';

type Props = {
  theme: Object,
  dispatch: Function,
  history: Object,
};

type State = {
  isAlertDisplayed: boolean,
  alertText: ?string,
}

class SearchBin extends React.PureComponent<Props, State> {
  static contextType = CertificateContext;

  state = {
    isAlertDisplayed: false,
    alertText: null,
  };

  componentDidMount() {
    covidApi.shouldDisplayAlert()
      .then(response => response.json())
      .then(response => this.setState({
        isAlertDisplayed: response.shouldDisplayAlert,
        alertText: response.text,
      }));
  }

  getData = async bin => binApi.fetch({ bin });

  onSubmit = (values) => {
    this.getData(values.bin)
      .then(res => res.json())
      .then((data) => {
        this.context.setBin(values.bin);
        this.props.history.push(`/${data.cd_banque.toLowerCase()}${REQUEST_CERTIFICATE_PAGE_ROUTE}`);
      })
      .catch(this.props.dispatch(notifyError));
  };

  parseQueryParams = () => {
    const validQueryParams = window.location.search.substring(1)
      .split('&')
      .filter(param => /^\w+[=][^&]+$/g.test(param))
      .join();
    return validQueryParams ?
      JSON.parse(`{"${
        decodeURI(validQueryParams)
          .replace(/"/g, '\\"')
          .replace(/,/g, '","')
          .replace(/=/g, '":"')}"}`) :
      {};
  };

  render() {
    const param = this.parseQueryParams();
    return (
      <>
        <div className="p-bin">
          <Header theme={this.props.theme} />
          <div className="u-container">
            {
              this.state.isAlertDisplayed &&
              <div className="covid-alert u-sansserif" style={{ background: this.props.theme.color }}>
                <div dangerouslySetInnerHTML={{ __html: this.state.alertText }} />
              </div>
            }
            <h2
              className="a-title__h2 u-margin-top-0 u-margin-left-0"
              style={{ color: this.props.theme.color }}
            >
              {I18n.t('bin.title')}
            </h2>
            <Form
              onSubmit={this.onSubmit}
              initialValues={{ bin: param.bin }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="a-input__bin">
                    <Field
                      label={I18n.t('bin.labelBinfield')}
                      type="text"
                      name="bin"
                      component={CoreozInputBase}
                      maxLength="9"
                      required
                      validate={composeValidators(required, checkBin)}
                      placeholder="_________"
                      autocomplete="off"
                    />
                  </div>
                  <p className="personal-data u-margin-top-20">
                    {I18n.t('bin.personalData1')}
                    <a href="https://www.mutuaide.fr/donnees-personnelles/" target="_blank">
                      {I18n.t('bin.personalDataLink')}
                    </a>
                    {I18n.t('bin.personalData2')}
                  </p>
                  <button
                    type="submit"
                    className="a-button__primary u-margin-top-20"
                    style={{ background: this.props.theme.color }}
                  >
                    {I18n.t('bin.submit')}
                  </button>
                </form>
              )}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(connect()(SearchBin));
