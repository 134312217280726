// @flow

import React from 'react';
import NotificationsSystem from 'reapop';
import { Switch, Route } from 'react-router-dom';
import { withGA } from './gogleAnalytics/GoogleAnalytics';

import theme from './components/modaleNotification/theme/index';

import {
  BIN_PAGE_FULL_ROUTE,
  REQUEST_CERTIFICATE_PAGE_FULL_ROUTE,
  REQUEST_CERTIFICATE_PAGE_ROUTE,
  SUMMARY_PAGE_FULL_ROUTE,
  VALIDATION_PAGE_FULL_ROUTE,
} from './const';
import CertificateContextProvider from './store/CertificateContext';

import ThemeContextProvider from './store/ThemeContext';
import './polyfill_startsWith';
import './polyfill_includes';
import RequestCertificatePage from './pages/RequestCertifcatePage';
import SummaryPage from './pages/SummaryPage';
import ValidationPage from './pages/ValidationPage';
import SearchBinPage from './pages/SearchBinPage';
import confApi from './network/api/confApi';
import Footer from './components/Footer/Footer';

type Props = {}

type State = {
  hasOneTrust: boolean,
};

class App extends React.Component<Props, State> {
  state = {
    hasOneTrust: false,
  };

  setupOnetrust = () => {
    confApi.fetchOnetrust()
      .then(response => response.text())
      .then((OTtoken) => {
        if (OTtoken !== '') {
          this.setState({ hasOneTrust: true });

          const scriptOt = document.createElement('script');
          scriptOt.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
          scriptOt.setAttribute('type', 'text/javascript');
          scriptOt.setAttribute('charset', 'UTF-8');
          scriptOt.setAttribute('data-domain-script', OTtoken);

          document.head.appendChild(scriptOt);

          confApi.getAnalyticsId()
            .then(response => response.text())
            .then((GAresponse) => {
              this.setupAnalitycs(GAresponse);
            });
        }
      });
  };

  setupAnalitycs = (token) => {
    if (token !== '') {
      const scriptOt = document.createElement('script');
      scriptOt.setAttribute('type', 'text/javascript');

      const scriptOtFunction = document.createTextNode('function OptanonWrapper() { OneTrust.OnConsentChanged(function() {window.location.reload()}) }');
      scriptOt.appendChild(scriptOtFunction);

      document.head.appendChild(scriptOt);

      const script = document.createElement('script');
      script.setAttribute('type', 'text/plain');
      script.classList.add('optanon-category-2');

      const scriptText = document.createTextNode(
        '(function(i,s,o,g,r,a,m){i[\'GoogleAnalyticsObject\']=r;i[r]=i[r]||function(){\n' +
        '(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n' +
        'm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n' +
        '})(window,document,\'script\',\'https://www.google-analytics.com/analytics.js\',\'ga\');\n' +
        '\n' +
        'ga(\'create\', \'' + token + '\', \'auto\');\n' +
        'ga(\'send\', \'pageview\');'
      );

      script.appendChild(scriptText);
      document.body.appendChild(script);
    }
  };

  setupCaptcha = () => {
    confApi.getCaptchaFrontKey()
      .then(response => response.json())
      .then(response => {
        if (response.isActivated) {
          const scriptCaptcha = document.createElement('script');
          scriptCaptcha.id = 'recaptchaScript';
          scriptCaptcha.type = 'text/javascript';
          scriptCaptcha.src = 'https://www.google.com/recaptcha/api.js';
          document.head.appendChild(scriptCaptcha);
        }
      });
  }

  componentDidMount() {
    this.setupOnetrust();
    this.setupCaptcha();
  }

  render() {
    return (
      <ThemeContextProvider>
        <CertificateContextProvider>
          <NotificationsSystem theme={theme} />
          <div className="app-container">
            <Switch>
              <Route path={REQUEST_CERTIFICATE_PAGE_FULL_ROUTE}>
                <RequestCertificatePage />
              </Route>
              <Route path={SUMMARY_PAGE_FULL_ROUTE}>
                <SummaryPage />
              </Route>
              <Route path={VALIDATION_PAGE_FULL_ROUTE}>
                <ValidationPage />
              </Route>
              <Route path={BIN_PAGE_FULL_ROUTE}>
                <SearchBinPage />
              </Route>
            </Switch>
            <Footer
              fixed={!this.props.location.pathname.includes(REQUEST_CERTIFICATE_PAGE_ROUTE)}
              hasOneTrust={this.state.hasOneTrust}
            />
          </div>
        </CertificateContextProvider>
      </ThemeContextProvider>
    );
  }
}

export default withGA({
  WrappedComponent: App,
});
