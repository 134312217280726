// @flow

import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { withRouter } from 'react-router-dom';
import { geocodeByAddress } from 'react-places-autocomplete';
import { BIN_PAGE_ROUTE, MUT_PARAM, SUMMARY_PAGE_ROUTE } from './../../const';
import Trip from './Trip';
import Holder from './Holder';
import Beneficiary from './Beneficiary';

import { CertificateContext } from '../../store/CertificateContext';
import Header from '../Header/Header';
import confApi from '../../network/api/confApi';
import covidApi from '../../network/api/covidApi';
import { notifyError } from '../../network/notification';

type Props = {
  theme: Object,
  history: Object,
  dispatch: Function,
};

type State = {
  holder: {
    zipaddress: string,
  },
  countryLabel: string,
  filterPage: boolean,
  autocompleteInfo: Object,
};

class RequestCertificate extends React.PureComponent<Props, State> {
  static contextType = CertificateContext;

  state = {
    holder: {
      zipaddress: '',
    },
    countryLabel: '',
    filterPage: true,
    autocompleteInfo: {},
  };

  componentWillMount() {
    if (this.context.bin) {
      this.setState({ filterPage: false });
    }
  }

  componentDidMount() {
    confApi.getAutocompleteInfos()
      .then(response => response.json())
      .then((response) => {
        const scriptId = 'mapsScript';
        if (!document.getElementById(scriptId) && response.isActivated) {
          const script = document.createElement('script');
          script.id = scriptId;
          script.src = `https://maps.googleapis.com/maps/api/js?key=${response.apiKey}&libraries=places`;
          document.body.appendChild(script);
          script.onload = () => this.setState({ autocompleteInfo: response });
        }
      });
  }

  onSubmit = (values) => {
    covidApi.checkTripDate({
      startDate: values.trip.startDate,
      endDate: values.trip.endDate,
    })
      .then(() => {
        this.context.setData({
          ...values,
          trip: {
            ...values.trip,
            countryLabel: this.state.countryLabel,
          },
        });
        this.props.history.push(`/${this.props.theme.company}${SUMMARY_PAGE_ROUTE}`);
      })
      .catch(this.props.dispatch(notifyError));
  };

  handleAddressChange = (address) => {
    this.setState({ holder: { address } });
  };

  setCountryLabel = (label) => {
    this.setState({ countryLabel: label });
  };

  handleAddressSelect = async (address, change) => {
    const addressComponent = await geocodeByAddress(address);
    const address_components = addressComponent[0].address_components;

    change('holder.city', '');
    change('holder.country', '');
    change('holder.zipCode', '');
    let displayedAddress = '';
    for (let i = 0; i < address_components.length; i++) {
      switch (address_components[i].types[0]) {
        case 'street_number':
          displayedAddress += `${address_components[i].long_name} `;
          break;
        case 'route':
          displayedAddress += address_components[i].long_name;
          break;
        case 'locality':
          change('holder.city', address_components[i].long_name);
          break;
        case 'country':
          change('holder.country', address_components[i].long_name);
          break;
        case 'postal_code':
          change('holder.zipCode', address_components[i].long_name);
          break;
        default:
          continue;
      }
    }
    change('holder.address', displayedAddress);
    this.handleAddressChange(displayedAddress);
  };

  handleDate = async (date, change, field) => {
    if (typeof date === "string") {
      let newDate = date.replace(/[a-zA-Z]+/g, '');

      if (newDate.length === 2 || newDate.length === 5) {
        newDate = newDate + '/';
      } else if (newDate.length > 6 && newDate.split('/').length === 1) {
        newDate = newDate.substring(0,2) + '/' + newDate.substring(2,4) + '/' + newDate.substring(5)
      } else if (newDate.length >= 3 && newDate.length < 6  && newDate.split('/').length === 1) {
        newDate = newDate.substring(0,2) + '/' + newDate.substring(2);
      } else if (newDate.length >= 6 && newDate.split('/').length <= 2) {
        newDate = newDate.substring(0,5) + '/' + newDate.substring(5)
      }

      change(field, newDate);
    }

  };

  render() {
    const { data } = this.context;
    const { color } = this.props.theme;
    if (this.state.filterPage) {
      this.props.history.push(`/${MUT_PARAM}${BIN_PAGE_ROUTE}`);
    }
    return (
      <>
        <>
          <Header theme={this.props.theme} />
          <div className="u-container">
            <Form
              autocomplete="off"
              onSubmit={values => this.onSubmit(values)}
              initialValues={data}
              mutators={{
                ...arrayMutators,
              }}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop },
                },
                form,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Trip
                    form={form}
                    name="trip"
                    handleDate={this.handleDate}
                    theme={this.props.theme}
                    startDate={values.trip && values.trip.startDate ? values.trip.startDate : ''}
                    country={values.trip && values.trip.zone}
                    setCountryLabel={this.setCountryLabel}
                  />
                  <Holder
                    form={form}
                    name="holder"
                    address={this.state.holder.address || data.holder.address}
                    handleChange={this.handleAddressChange}
                    handleSelect={address =>
                      this.handleAddressSelect(address, form.change)
                    }
                    birthday={values.holder.birthDay}
                    theme={this.props.theme}
                    autocompleteInfo={this.state.autocompleteInfo}
                    handleDate={this.handleDate}
                  />
                  <Beneficiary
                    form={form}
                    addBeneficiary={push}
                    removeBeneficiary={pop}
                    data={values}
                    theme={this.props.theme}
                    handleDate={this.handleDate}
                  />
                  <button
                    className="a-button__primary u-margin-bottom-30"
                    type="submit"
                    style={{ background: color }}
                  >
                    {I18n.t('requestCertificate.submit')}
                  </button>
                </form>
              )}
            />
          </div>
        </>
      </>
    );
  }
}

export default withRouter(connect()(RequestCertificate));
