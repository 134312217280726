// @flow

import { RestClient } from '../network';

const covidApi = {
  shouldDisplayAlert: () => new RestClient('/covid/alert', 'GET').execute(),
  checkTripDate: (data: Object) => new RestClient('/covid/trip', 'POST').jsonBody(data).execute(),
};

export default covidApi;
