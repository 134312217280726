// @flow

import React from 'react';
import PhoneInput from 'react-phone-number-input/max';
import 'react-phone-number-input/style.css';

type Props = {
  label?: string,
  placeholder?: string,
  input: {
    value: ?string,
    onChange: Function
  },
  disabled?: boolean,
  meta: {
    error: string,
    touched: boolean
  },
  onChange: Function,
  required: boolean,
  className: string
};

export default class PhoneInputMut extends React.PureComponent<Props, State> {
  render() {
    const { label, input, disabled, meta, required, className } = this.props;
    const isEmpty = !input.value && input.value !== 0 && input.value !== false;
    const hasError = meta.touched && meta.error;

    return (
      <>
        <div className={`base-container-input-coreoz  ${className}`}>
          {label && (
            <div
              className={`base-label-input-coreoz base-label-input-coreoz--required  ${
                isEmpty ? 'base-label-input-coreoz--empty' : ''
              } ${required ? 'required' : ''} ${hasError ? 'label-input-oav--error' : ''}`}
            >
              {label}
            </div>
          )}
          <PhoneInput
            displayInitialValueAsLocalNumber
            country="FR"
            defaultCountry="FR"
            disabled={disabled}
            {...input}
          />
          {hasError && (
            <div className="base-error-form error-form--field">{!!meta && meta.error}</div>
          )}
        </div>
      </>
    );
  }
}
