// @flow

import { RestClient } from '../network';

const confApi = {
  getAutocompleteInfos: () => new RestClient('/conf/autocomplete', 'GET').execute(),
  getCaptchaFrontKey: () => new RestClient('/conf/captcha', 'GET').execute(),
  getAnalyticsId: () => new RestClient('/conf/analytics', 'GET').execute(),
  fetchOnetrust: () => new RestClient('/conf/onetrust', 'GET').execute(),
};

export default confApi;
