// @flow

import React from 'react';
import { ThemeContext } from '../store/ThemeContext';
import { getParamCompany } from './../utils';
import Summary from '../components/Summary/Summary';

type Props = {};

class SummaryPage extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  componentDidMount() {
    this.context.setTheme(getParamCompany());
  }

  render() {
    return <Summary theme={this.context.theme} />;
  }
}

export default SummaryPage;
