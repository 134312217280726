// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { CertificateContext } from '../../store/CertificateContext';
import { BIN_PAGE_ROUTE, GROUPED, MUT_PARAM } from './../../const';

type Props = {
  theme: Object,
  history: Object,
};

type State = {
  filterPage: boolean,
};

class Validation extends React.PureComponent<Props, State> {
  static contextType = CertificateContext;

  state = { filterPage: true };

  componentWillMount() {
    if (this.context.bin) {
      this.setState({ filterPage: false });
    }
  }

  render() {
    const { data } = this.context;
    const { holder } = data;
    const { theme } = this.props;
    if (this.state.filterPage) {
      this.props.history.push(`/${MUT_PARAM}${BIN_PAGE_ROUTE}`);
    }
    return (
      <>
        <div className="p-summary" style={{ backgroundImage: `url(${theme.img})` }}>
          <div className="a-logo">
            <img
              src={theme.logo}
              alt="logo"
              onClick={() => {
                this.context.setData({ trip: {}, holder: { certificate: GROUPED }, beneficiary: [] });
                this.props.history.push(`/${theme.company}${BIN_PAGE_ROUTE}`);
              }}
              className="logo"
            />
          </div>
          <div className="u-container">
            <div className="t-summary u-clearfix t-validation">
              <div className="a-icon__check" style={{ background: theme.color }}></div>
              <div className="m-validation">
                <h2 className="a-text__larg">
                  {holder.firstName} {holder.lastName},
                </h2>
                <p className="a-label">{ReactHtmlParser(I18n.t('validation.msg'))}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Validation);
