// @flow

import React from 'react';
import { ThemeContext } from '../store/ThemeContext';
import { getParamCompany } from './../utils';
import Validation from '../components/Validation/Validation';

type Props = {};

class ValidationPage extends React.PureComponent<Props> {
  static contextType = ThemeContext;

  componentDidMount() {
    this.context.setTheme(getParamCompany());
  }

  render() {
    return <Validation theme={this.context.theme} />;
  }
}

export default ValidationPage;
