// @flow

import moment from 'moment';
import { isValidPhoneNumber } from 'react-phone-number-input/max';
import { REQUIRED_FIELD } from './const';

export const required = value => (value && value !== {} ? undefined : REQUIRED_FIELD);

export const validDate = value => !value || moment.isMoment(value) ? undefined : 'Le format est invalide (attendu : JJ/MM/AAAA)';

export const isValidPhone = value =>
  (value && !isValidPhoneNumber(value) && !/^((\+)337)\d{8}$/.test(value)
    ? "Le numéro n'est pas valide"
    : undefined);

export const dateNotInFuture = (value) => {
  const dateNow = new Date();
  const choosedDate = new Date(value);
  return choosedDate > dateNow ? 'La date est invalide' : undefined;
};

const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce((error, validator) => error || validator(value, allValues), undefined);

export const maxLength = max => value =>
  (value && value.length > max ? `Doit comporter ${max} caractères ou moins` : undefined);
export const maxLength255 = maxLength(255);
export const maxLength9 = maxLength(9);
export const maxLength5 = maxLength(5);

export const minLength = min => value =>
  (value && value.length < min ? `Doit comporter ${min} caractères` : undefined);
export const minLength9 = minLength(9);

export const checkMailValidity = value =>
  (value && !mailRegex.test(value) ? 'Erreur – l\'adresse saisie ne respecte pas le format : xxxxx@xxx.xx' : undefined);

export const numeric = value => (value && isNaN(Number(value)) ? 'Uniquement des chiffres' : undefined);

export const alphabet = value =>
  (value && /[^a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'\- ]/i.test(value)
    ? 'Uniquement des lettres, pas de nombre ou autre caratère'
    : undefined);

export const mailMatch = (value, allValues) =>
  (value && value === allValues.holder.email ? undefined : 'Erreur – les adresses saisies ne sont pas identiques');

export const checkEndDate = (value, allValues) =>
  (value && new Date(allValues.trip.startDate) <= new Date(value)
    ? undefined
    : 'La date de fin ne peut pas être avant la date de début');

export const zipCode = composeValidators(numeric, maxLength5);
export const city = composeValidators(maxLength255);
export const checkBin = composeValidators(numeric, maxLength9, minLength9);
