// @flow

import React from 'react';
import ReactGA from 'react-ga';

type Props = {
  WrappedComponent: React.Component,
};

export function withGA({ WrappedComponent }: Props) {
  const trackPage = (page) => {
    ReactGA.set({
      screenName: page,
    });

    ReactGA.pageview('/' + page);
  };

  return class extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
      const previousUrl = prevProps.location.pathname;
      const newUrl = this.props.location.pathname;

      if (previousUrl !== newUrl) {
        trackPage(newUrl);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  };
}
