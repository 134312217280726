// @flow

import React from 'react';

export type Props = {|
  message: string,
  color: string,
  className: string,
|};

function Tooltip({ message, color, className = '' }: Props) {
  return (
    <div className={`${className} base tip-container`}>
      <i className="fa fa-exclamation-triangle" style={{ color }} />
      <div className="tip override" style={{ backgroundColor: color, color }}><span>{message}</span></div>
    </div>
  );
}

export default Tooltip;
