// @flow

import React from 'react';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import { CoreozInputBase, CoreozDatePickerBase, CoreozSelectBase } from 'coreoz-form-base';
import {
  city,
  zipCode,
  alphabet,
  checkMailValidity,
  isValidPhone,
  required,
  dateNotInFuture,
  mailMatch,
  validDate,
  composeValidators,
} from '../../validators';
import AddressAutocomplete from './AddressAutocomplete';
import { DATE_FORMAT, DATE_PLACEHOLDER, LANGUES } from '../../const';
import PhoneInputMut from '../PhoneInputMut/PhoneInputMut';
import Tooltip from '../tooltip/Tooltip';

type Props = {
  name: string,
  address: string,
  handleChange: Function,
  handleSelect: Function,
  birthday: Object,
  theme: Object,
  isAutocompleteActive: Object,
  handleDate: Function,
  form: Object,
};


const Holder = ({ name, address, handleChange, handleSelect, birthday, theme, autocompleteInfo, form, handleDate }: Props) => {
  const getAge = () => {
    const age = moment().diff(birthday, 'years');
    return age > 0 ? age : 0;
  };

  const age = getAge();
  const { color } = theme;
  return (
    <>
      <div className="div-tooltip">
        <h2 className="a-title__h2 u-margin-left-10" style={{ color }}>
          {I18n.t('requestCertificate.holder.holderInformation')}
        </h2>
        <Tooltip message={I18n.t('requestCertificate.holder.exactInfos')} color={theme.color} className="tooltip" />
      </div>
      <div>
        <Field
          label={I18n.t('requestCertificate.holder.name')}
          name={`${name}.lastName`}
          component={CoreozInputBase}
          type="text"
          validate={composeValidators(required, alphabet)}
          required
        />
      </div>

      <div>
        <Field
          label={I18n.t('requestCertificate.holder.firstname')}
          name={`${name}.firstName`}
          component={CoreozInputBase}
          type="text"
          validate={composeValidators(required, alphabet)}
          required
        />
      </div>

      <div className="a-input__birthday">
        <Field
          label={I18n.t('requestCertificate.holder.birthday')}
          name={`${name}.birthDay`}
          component={CoreozDatePickerBase}
          validate={composeValidators(required, validDate, dateNotInFuture)}
          locale="fr-fr"
          dateFormat={DATE_FORMAT}
          onlyDate
          required
          placeholder={DATE_PLACEHOLDER}
          onChange={(e) => {handleDate(e, form.change, `${name}.birthDay`)}}
          maxLength="10"
        />
        <span className="a-input__birthday--age">
          {`${age} ${I18n.t('requestCertificate.holder.year')}${age > 1 ? 's' : ''}`}
        </span>
      </div>

      {autocompleteInfo && autocompleteInfo.isActivated ?
        <Field
          name={`${name}.address`}
          validate={required}
          render={props => (
            <AddressAutocomplete
              address={address}
              handleChange={handleChange}
              handleSelect={handleSelect}
              nextProps={props}
              autocompleteInfo={autocompleteInfo}
            />
          )}
        />
        :
        <div>
          <Field
            label={I18n.t('requestCertificate.holder.zipAddress')}
            name={`${name}.address`}
            component={CoreozInputBase}
            type="text"
            validate={composeValidators(required)}
            required
          />
        </div>
      }

      <div className="a-input__zip">
        <Field
          label={I18n.t('requestCertificate.holder.zip')}
          name={`${name}.zipCode`}
          component={CoreozInputBase}
          validate={required}
          required
        />
      </div>

      <div className="a-input__city">
        <Field
          label={I18n.t('requestCertificate.holder.city')}
          name={`${name}.city`}
          component={CoreozInputBase}
          validate={composeValidators(required, city)}
          required
        />
      </div>

      <div>
        <Field
          label={I18n.t('requestCertificate.holder.country')}
          name={`${name}.country`}
          component={CoreozInputBase}
          validate={composeValidators(required, alphabet)}
          required
        />
      </div>

      <div className="a-input__tel">
        <Field
          label={I18n.t('requestCertificate.holder.tel')}
          name={`${name}.telephone`}
          component={PhoneInputMut}
          type="text"
          required
          validate={composeValidators(required, value => isValidPhone(value))}
        />
      </div>

      <div>
        <Field
          label={I18n.t('requestCertificate.holder.email')}
          name={`${name}.email`}
          component={CoreozInputBase}
          validate={composeValidators(required, checkMailValidity)}
          required
        />
      </div>

      <div>
        <Field
          label={I18n.t('requestCertificate.holder.confirmationEmail')}
          name={`${name}.confirmEmail`}
          component={CoreozInputBase}
          validate={composeValidators(required, mailMatch, checkMailValidity)}
          required
        />
      </div>
      <div>
        <Field
          label={I18n.t('requestCertificate.holder.lang')}
          name={`${name}.language`}
          component={CoreozSelectBase}
          validate={required}
          list={LANGUES}
          required
        />
      </div>
    </>
  );
};

export default Holder;
