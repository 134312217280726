// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'react-final-form';
import { CoreozSelectBase, CoreozDatePickerBase } from 'coreoz-form-base';
import countriesApi from '../../network/api/countriesApi';
import { required, validDate, composeValidators, checkEndDate } from '../../validators';
import { DATE_FORMAT, DATE_PLACEHOLDER } from '../../const';
import Tooltip from '../tooltip/Tooltip';

type Props = {
  name: string,
  theme: Object,
  form: Object,
  startDate: Object,
  setCountryLabel: Function,
  country: string,
  handleDate: Function,
};

type State = {
  countries: Object[],
};

class Trip extends React.PureComponent<Props, State> {
  state = {
    countries: [],
  };

  componentWillMount() {
    this.getCountries()
      .then(res => res.json())
      .then((data) => {
        const countries = [];
        for (let i = 0; i < data.length; i++) {
          countries.push({ id: data[i].payid, label: data[i].payll });
        }
        countries.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

        this.setState({ countries });

        if (this.props.country) {
          this.props.setCountryLabel(countries.filter(c => c.id === this.props.country)[0].label);
        }
      });
  }

  getCountries = async () => {
    return await countriesApi.fetch();
  };

  isValidDate = (current) => {
    const startDate = new Date(this.props.startDate);
    const chosenDate = new Date(current);
    return startDate <= chosenDate;
  };

  render() {
    const { name, theme } = this.props;

    return (
      <>
        <div className="div-tooltip">
          <h2 className="a-title__h2 u-margin-top-0 u-margin-left-10" style={{ color: theme.color }}>
            {I18n.t('requestCertificate.trip.title')}
          </h2>
          <Tooltip message={I18n.t('requestCertificate.trip.warningCountry')} color={theme.color} className="tooltip" />
        </div>
        <div className="a-input__trip">
          <Field
            label={I18n.t('requestCertificate.trip.area')}
            name={`${name}.zone`}
            component={CoreozSelectBase}
            validate={required}
            list={this.state.countries}
            required
            onChange={e => this.props.setCountryLabel(this.state.countries.filter(c => c.id === e)[0].label)}
          />
        </div>
        <div className="a-input__tripdate">
          <Field
            label={I18n.t('requestCertificate.trip.startDate')}
            name={`${name}.startDate`}
            className="u-sansserif"
            component={CoreozDatePickerBase}
            validate={composeValidators(required, validDate)}
            locale="fr-fr"
            dateFormat={DATE_FORMAT}
            onlyDate
            required
            placeholder={DATE_PLACEHOLDER}
            maxLength="10"
            onChange={(e) => {this.props.handleDate(e, this.props.form.change, `${name}.startDate`)}}
          />
        </div>
        <div className="a-input__tripdate">
          <Field
            label={I18n.t('requestCertificate.trip.endDate')}
            name={`${name}.endDate`}
            component={CoreozDatePickerBase}
            validate={composeValidators(required, validDate, checkEndDate)}
            locale="fr-fr"
            dateFormat={DATE_FORMAT}
            isValidDate={this.isValidDate}
            onlyDate
            required
            placeholder={DATE_PLACEHOLDER}
            onChange={(e) => {this.props.handleDate(e, this.props.form.change, `${name}.endDate`)}}
            maxLength="10"
            viewDate={ this.props.form.getState().values.trip ?
              this.props.form.getState().values.trip.endDate
              || this.props.form.getState().values.trip.startDate : ''}
          />
        </div>
      </>
    );
  }
}

export default Trip;
