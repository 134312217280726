// @flow

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { I18n } from 'react-redux-i18n';

type Props = {
  address: string,
  handleChange: Function,
  handleSelect: Function,
  nextProps: Object,
  autocompleteInfo: Object
};

const AddressAutocomplete = ({ address, handleChange, handleSelect, nextProps, autocompleteInfo }: Props) => {
  const hasError = nextProps.meta.touched && nextProps.meta.error;
  return (
    <PlacesAutocomplete
      value={address || ''}
      onChange={(e) => {
        handleChange(e);
        nextProps.input.onChange(e);
      }}
      onSelect={handleSelect}
      shouldFetchSuggestions={!!(address && address.length > autocompleteInfo.lengthBeforeQuery)}
      debounce={autocompleteInfo.delayBeforeQuery}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="base-container-input-coreoz">
          <label className="base-label-input-coreoz base-label-input-coreoz--required">
            {I18n.t('requestCertificate.holder.zipAddress')}
          </label>
          <input
            {...getInputProps({
              className: `base-input-coreoz ${hasError ? 'base-input-coreoz--error' : ''}`,
            })}
            {...nextProps}
          />
          {hasError ? <div className="base-error-form error-form--field">champ requis</div> : ''}
          <div className="m-autocomplete">
            {loading && <div className="a-label">{I18n.t('requestCertificate.loading')}...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span className="a-label">{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressAutocomplete;
