// @flow

import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  fixed: boolean,
  hasOneTrust: boolean,
}

const Footer = ({ fixed, hasOneTrust }: Props) => (
  <footer className={`o-footer ${fixed ? 'fixed' : ''}`}>
    <div className="u-container">
      <a href="https://www.mutuaide.fr/mentions-legales/" className="a-link" target="_blank">
        {I18n.t('footer.legalnotice')}
      </a>
      <span className="o-footer__sep" />
      <a href="https://www.mutuaide.fr/donnees-personnelles/" className="a-link" target="_blank">
        {I18n.t('footer.personaldata')}
      </a>
      {
        hasOneTrust &&
        <Fragment>
          <span className="o-footer__sep" />
          <a className="a-link optanon-toggle-display">
            {I18n.t('footer.manageCookies')}
          </a>
        </Fragment>
      }
    </div>
  </footer>
);

export default Footer;
