// @flow

export const baseApiUrl = '/api';

// Routes
export const BASE_ROUTE = '/:bank?';
export const BIN_PAGE_ROUTE = '/';
export const REQUEST_CERTIFICATE_PAGE_ROUTE = '/attestation';
export const SUMMARY_PAGE_ROUTE = '/recapitulatif';
export const VALIDATION_PAGE_ROUTE = '/validation';
export const BIN_PAGE_FULL_ROUTE = `${BASE_ROUTE}${BIN_PAGE_ROUTE}`;
export const REQUEST_CERTIFICATE_PAGE_FULL_ROUTE = `${BASE_ROUTE}${REQUEST_CERTIFICATE_PAGE_ROUTE}`;
export const SUMMARY_PAGE_FULL_ROUTE = `${BASE_ROUTE}${SUMMARY_PAGE_ROUTE}`;
export const VALIDATION_PAGE_FULL_ROUTE = `${BASE_ROUTE}${VALIDATION_PAGE_ROUTE}`;

// Company params
export const CARMA_PARAM = 'carma';
export const MUT_PARAM = 'mutuaide';

// Globals
export const GROUPED = 'GROUPED';
export const INDIVIDUAL = 'INDIVIDUAL';

export const LANGUES = [
  { id: 'FRA', label: 'Français' },
  { id: 'GBR', label: 'Anglais' },
  { id: 'ESP', label: 'Espagnol (obligatoire si vous partez à Cuba)' },
];

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_PLACEHOLDER = 'JJ/MM/AAAA';

export const NOTIFY_CONFIRMATION = {
  message: 'Les modifications ont bien été prises en compte',
  status: 'success',
  dismissible: true,
  dismissAfter: 3000,
};

export const REQUIRED_FIELD = 'champ requis';
