// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { BIN_PAGE_ROUTE } from '../../const';

type Props = {
  theme: Object,
  history: Object,
};

const Header = ({ theme, history }: Props) => (
  <header className="o-header" style={{ backgroundImage: `url(${theme.img})` }}>
    <div className="a-logo">
      <img src={theme.logo} alt="logo" onClick={() => history.push(`/${theme.company}${BIN_PAGE_ROUTE}`)} className="logo" />
    </div>
  </header>
);

export default withRouter(Header);
