// @flow

import React from 'react';
import { remove } from 'remove-accents';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { ReCAPTCHA } from 'react-google-recaptcha';
import {
  BIN_PAGE_ROUTE,
  GROUPED,
  LANGUES,
  MUT_PARAM,
  REQUEST_CERTIFICATE_PAGE_ROUTE,
  VALIDATION_PAGE_ROUTE,
} from './../../const';
import { CertificateContext } from '../../store/CertificateContext';
import attestationApi from '../../network/api/attestationApi';
import { notifyError } from '../../network/notification';
import confApi from '../../network/api/confApi';

type Props = {
  theme: Object,
  history: Object,
  dispatch: Function,
};

type State = {
  isSummaryCertified: boolean,
  filterPage: boolean,
  loading: boolean,
  captchaAnswer: ?string,
  captchaKeyFront: ?string,
  captchaActivated: boolean,
};

class Summary extends React.PureComponent<Props, State> {
  static contextType = CertificateContext;

  state = {
    isSummaryCertified: false,
    filterPage: true,
    loading: false,
    captchaAnswer: null,
    captchaKeyFront: null,
    captchaActivated: false,
  };

  componentWillMount() {
    if (this.context.bin) {
      this.setState({ filterPage: false });
    }
  }

  componentDidMount() {
    confApi.getCaptchaFrontKey()
      .then(response => response.json())
      .then(response => this.setState({
        captchaKeyFront: response.key,
        captchaActivated: response.isActivated,
      }));
  }

  generateDate = (date) => {
    const fullDate = new Date(date);
    const year = fullDate.getFullYear();
    const month =
      fullDate.getMonth() >= 9 ? fullDate.getMonth() + 1 : `0${fullDate.getMonth() + 1}`;
    const day = fullDate.getDate() >= 10 ? fullDate.getDate() : `0${fullDate.getDate()}`;

    return `${day}/${month}/${year}`;
  };

  handleChangeValidation = (event: EventHandler) => {
    this.setState({ isSummaryCertified: event.target.checked });
  };

  handleChangeCaptcha = (e) => {
    this.setState({ captchaAnswer: e });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const data = {
      trip: {
        ...this.context.data.trip,
        zone: [this.context.data.trip.zone],
      },
      holder: {
        ...this.context.data.holder,
        country: remove(this.context.data.holder.country.toUpperCase()),
      },
      beneficiaries: this.context.data.beneficiary,
      bin: this.context.bin,
      captcha: this.state.captchaAnswer,
    };
    attestationApi
      .send(data)
      .then(() => this.props.history.push(`/${this.props.theme.company}${VALIDATION_PAGE_ROUTE}`))
      .catch((err) => {
        this.props.dispatch(notifyError)(err);
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      color, img, logo, company,
    } = this.props.theme;
    const { data } = this.context;
    const { holder, beneficiary, trip } = data;
    if (this.state.filterPage) {
      this.props.history.push(`/${MUT_PARAM}${BIN_PAGE_ROUTE}`);
    }

    const grecaptchaObject = window.grecaptcha;

    return (
      <>
        <div className="p-summary" style={{ backgroundImage: `url(${img})` }}>
          <div className="a-logo">
            <img
              src={logo}
              alt="logo"
              onClick={() => {
                this.context.setData({ trip: {}, holder: { certificate: GROUPED }, beneficiary: [] });
                this.props.history.push(`/${company}${BIN_PAGE_ROUTE}`);
              }}
              className="logo"
            />
          </div>
          <div className="u-container">
            <div className="t-summary">
              <h2 className="a-title__h3 u-pull-left u-margin-top-10">
                {I18n.t('summary.title')}
              </h2>
              <button
                type="button"
                className="a-button__edit u-pull-right"
                style={{ background: color }}
                onClick={() => this.props.history.push(`/${company}${REQUEST_CERTIFICATE_PAGE_ROUTE}`)}
              >
                {I18n.t('summary.edit')}
              </button>
              <div className="a-title__h4 u-clearfix u-margin-bottom-20">{I18n.t('summary.subtitle')}</div>
              <h3 className="a-title__h2 u-clearfix" style={{ color }}>
                {I18n.t('summary.trip.title')}
              </h3>
              <ul>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.trip.zone')} </span>
                  <strong className="a-text__small u-capitalize">{trip.countryLabel}</strong>
                </li>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.trip.date')} </span>
                  <strong className="a-text__small u-sansserif">
                    {`${this.generateDate(trip.startDate)} . ${this.generateDate(trip.endDate)}`}
                  </strong>
                </li>
              </ul>
              <h3 className="a-title__h2" style={{ color }}>
                {I18n.t('summary.holder.title')}
              </h3>
              <h4 className="a-text__medium">
                {holder.lastName} {holder.firstName}
              </h4>
              <ul>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.holder.birthday')} </span>
                  <strong className="a-text__small u-sansserif">
                    {this.generateDate(holder.birthDay)}
                  </strong>
                </li>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.holder.zipAddress')} </span>
                  <strong className="a-text__small">
                    {holder.address}, {holder.zipCode}, {holder.city}, {holder.country}
                  </strong>
                </li>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.holder.tel')} </span>
                  <strong className="a-text__small u-sansserif">{holder.telephone}</strong>
                </li>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.holder.email')} </span>
                  <strong className="a-text__small">{holder.email}</strong>
                </li>
                <li className="u-clearfix">
                  <span className="a-title__h4">{I18n.t('summary.holder.lang')} </span>
                  <strong className="a-text__small">
                    {holder.language && LANGUES.filter(l => l.id === holder.language)[0].label}
                  </strong>
                </li>
              </ul>
              {beneficiary.length ? (
                <>
                  <h3 className="a-title__h2" style={{ color }}>
                    {I18n.t('summary.beneficiary.title')}
                  </h3>
                  <div>
                    <span className="a-title__h4">
                      {I18n.t('requestCertificate.beneficiary.certificate')}
                    </span>
                    <strong className="a-text__small">
                      {holder.certificate === GROUPED
                        ? I18n.t('requestCertificate.beneficiary.grouped')
                        : I18n.t('requestCertificate.beneficiary.individual')}
                    </strong>
                  </div>
                </>
              ) : (
                ''
              )}
              {beneficiary &&
                beneficiary.map(beneficiary => (
                  <div key={beneficiary.lastName}>
                    <h4 className="a-text__medium">
                      {beneficiary.lastName} {beneficiary.firstName}
                    </h4>
                    <ul>
                      <li className="u-clearfix">
                        <span className="a-title__h4">
                          {I18n.t('summary.beneficiary.birthday')}
                        </span>
                        <strong className="a-text__small u-sansserif">
                          {this.generateDate(beneficiary.birthDay)}
                        </strong>
                      </li>
                      <li className="u-clearfix">
                        <span className="a-title__h4">
                          {I18n.t('summary.beneficiary.relationship')}
                        </span>
                        <strong className="a-text__small">{beneficiary.relationship}</strong>
                      </li>
                    </ul>
                  </div>
                ))
              }
              <div className="a-input__checkbox u-clearfix u-margin-top-10 u-margin-bottom-20">
                <label className="a-label" onClick={this.handleChangeValidation} style={{ color }}>
                  <input type="checkbox" />
                  <span className={`${company} checkmark`} />
                  <span className="bold">{I18n.t('summary.validate')}</span>
                </label>
              </div>

              {this.state.captchaActivated && this.state.captchaKeyFront &&
                <div className="captcha">
                  <ReCAPTCHA
                    sitekey={this.state.captchaKeyFront}
                    size="normal"
                    onChange={e => this.handleChangeCaptcha(e)}
                    grecaptcha={grecaptchaObject}
                  />
                </div>
              }
            </div>

            <button
              type="button"
              className="a-button__primary u-margin-bottom-30"
              style={{ background: color }}
              disabled={(this.state.captchaActivated && !this.state.captchaAnswer) || !this.state.isSummaryCertified || this.state.loading ? 'disabled' : false}
              onClick={() => this.handleSubmit()}
            >
              <i className={`fa fa-spinner fa-spin loader--${this.state.loading ? 'visible' : 'hidden'}`} />
              <span className="loader-label">{I18n.t('summary.submit')}</span>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(connect()(Summary));
