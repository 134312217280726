// @flow

import React from 'react';
import { GROUPED } from '../const';

export const CertificateContext = React.createContext();

type Props = {};

type State = {
  bin: string,
  data: {
    trip: Object,
    holder: {
      certificate: string
    },
    beneficiary: Object[],
  },
};

class CertificateContextProvider extends React.PureComponent<Props, State> {
  state = {
    bin: '',
    data: { trip: {}, holder: { certificate: GROUPED }, beneficiary: [] },
  };
  setData = (values) => {
    this.setState({ data: values });
  };
  setBin = (bin) => {
    this.setState({ bin });
  };
  render() {
    return (
      <CertificateContext.Provider
        value={{
          ...this.state,
          setData: this.setData,
          setBin: this.setBin,
        }}
      >
        {this.props.children}
      </CertificateContext.Provider>
    );
  }
}

export default CertificateContextProvider;
