// @flow

import React from 'react';
import { CARMA_PARAM, MUT_PARAM } from '../const';
import carmaBg from '../img/carma-bg.jpg';
import carmaLogo from '../img/carma-logo.PNG';
import mutuaideBg from '../img/mutuaide-bg.png';
import mutuaideLogo from '../img/mutuaide-logo.png';

export const ThemeContext = React.createContext();

type Props = {};

type State = {
  theme: Object,
};

class ThemeContextProvider extends React.PureComponent<Props, State> {
  state = {
    theme: {},
  };

  setTheme = (company: string) => {
    switch (company.toLowerCase()) {
      case CARMA_PARAM:
        this.setState({
          theme: {
            color: '#00b1eb',
            img: carmaBg,
            logo: carmaLogo,
            company: CARMA_PARAM,
          },
        });
        break;
      default:
        this.setState({
          theme: {
            color: '#00ab7f',
            img: mutuaideBg,
            logo: mutuaideLogo,
            company: MUT_PARAM,
          },
        });
    }
  };
  render() {
    return (
      <ThemeContext.Provider value={{ ...this.state, setTheme: this.setTheme }}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContextProvider;
