const fr = {
  bin: {
    title: 'Ma demande d\'attestation',
    labelBinfield: 'Saisissez les 9 premiers chiffres de votre carte bancaire',
    submit: 'Envoyer',
    personalData1: 'Mutuaide traite vos données personnelles afin de générer et de vous transmettre votre attestation d’assurance. Consultez notre ',
    personalDataLink: 'Politique de confidentialité',
    personalData2: ' pour en savoir plus sur les traitements de données et vos droits.',
  },
  requestCertificate: {
    submit: 'Valider la demande',
    loading: 'Chargement',
    trip: {
      title: 'Votre voyage',
      warningCountry: 'Une attestation mentionnant précisément le pays est obligatoire si vous voyagez dans les pays suivants : Cuba, Chine, Algérie, Russie, Biélorussie. Ne pas sélectionner une des zones',
      area: 'Choisissez votre pays ou zone de destination',
      date: 'Choisissez vos dates de début et fin de voyage',
      startDate: 'Date de début de voyage',
      endDate: 'Date de fin de voyage',
    },
    holder: {
      holderInformation: 'Mes informations titulaire',
      exactInfos: 'Veuillez saisir vos informations d\'identité comme figurant sur votre pièce d\'identité / passeport. Toute information inexacte peut entrainer un refus de délivrance du visa',
      year: 'An',
      name: 'Votre nom',
      firstname: 'Votre prénom',
      birthday: 'Votre date de naissance',
      zipAddress: 'Votre adresse postale',
      zip: 'Votre code postal',
      city: 'Votre ville',
      country: 'Votre pays de résidence',
      tel: 'Votre numéro de téléphone',
      email: 'Votre adresse mail',
      confirmationEmail: 'Confirmez votre adresse mail',
      lang: 'Choisissez la langue de votre attestation',
    },
    beneficiary: {
      conditions: 'Pour connaître la liste des bénéficiaires, veuillez consulter la notice d\'information de votre carte bancaire',
      addbeneficiary: 'Ajouter un nouveau bénéficiaire',
      beneficiary: 'Bénéficiaire',
      name: 'Nom',
      firstName: 'Prénom',
      birthday: 'Date de naissance',
      relationship: 'Lien de parenté',
      certificate: 'Attestation',
      grouped: 'Groupée',
      individual: 'Individuelle',
    },
  },
  summary: {
    title: 'Récapitulatif',
    subtitle: 'Veuillez vérifier l’exactitude des informations ci-dessous :',
    submit: 'Confirmer',
    edit: 'Modifier',
    validate: 'Je certifie l\'exactitude et la véracité des données saisies',
    trip: {
      title: 'Votre voyage',
      zone: 'Pays/Zone de destination',
      date: 'Dates de voyage',
    },
    holder: {
      title: 'Titulaire de la carte bancaire',
      holderInformation: 'Mes informations titulaire',
      birthday: 'Date de naissance',
      zipAddress: 'Adresse postale',
      tel: 'Téléphone',
      email: 'Adresse email',
      lang: 'Langue de l\'attestation',
    },
    beneficiary: {
      title: 'Les bénéficiaires',
      birthday: 'Date de naissance',
      relationship: 'Lien de parenté',
    },
  },
  footer: {
    personaldata: 'Données personnelles',
    legalnotice: 'Mentions légales',
    manageCookies: 'Gérer mes cookies',
  },
  validation: {
    msg: 'Votre demande d’attestation a bien été prise en compte. Vous recevrez votre / vos attestation(s) à l’adresse email indiquée.<br><br><b>Si vous ne recevez pas cet email</b>, veuillez vérifier vos courriers indésirables avant de nous contacter au numéro d’assistance indiqué au dos de votre carte bancaire',
  },
  example: {
    modalContent: 'Je suis une modale',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    ERROR: 'Les modifications ont échoués',
    YES: 'Oui',
    NO: 'Non',
  },
  wsError: {
    INTERNAL_ERROR: 'Erreur de connexion',
    AUTHENTIFICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse e-mail \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS:
      'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS:
      'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS:
      'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT:
      'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    BIN_NOT_KNOWN: 'Erreur d’identification – Veuillez saisir à nouveau les 9 premiers chiffres de votre carte bancaire',
    LANGUAGE_NOT_KNOWN: 'La langue demandée n\'est pas reconnue',
    ATTESTATION_FAILED: 'Une erreur est survenue pendant la demande d\'attestation',
    AT_LEAST_ONE_COUNTRY: 'Il faut au moins un pays ou zone',
    INVALID_CAPTCHA: 'Le captcha est expiré',
    INVALID_DATE_COVID: 'Votre demande concerne un voyage compris dans une période de restriction des déplacements. Nous vous remercions de modifier vos dates de séjour.',
    MESSAGE: '%{field0}',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
};

export default fr;
